import {
  FETCH_ALL_DEVICES_BY_PRODUCT,
  FETCH_ALL_DEVICES_LOGS,
  FETCH_DEVICES,
  FETCH_DEVICES_FIRMWARE_STATUS_IN_RANGE,
  FETCH_DEVICES_IN_RANGE,
  FETCH_DEVICE_LOGS,
} from '../actions'

import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_DEVICES:
      if (action.payload.data) {
        return {
          ...state,
          data: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
            }
          }),
          length: Number(action.payload.headers.length),
        }
      }

      return { ...state }

    case FETCH_DEVICE_LOGS:
      if (action.error) {
        return { ...state }
      }

      if (action.payload.data) {
        return {
          ...state,
          data: action.payload.data.map((row) => {
            return {
              ...row,
              createdAt: moment(row.createdAt).utc().format('lll'),
            }
          }),
          length: Number(action.payload.headers.length),
        }
      }

      return { ...state }

    case FETCH_DEVICES_IN_RANGE:
      if (action.error) {
        return { ...state }
      }

      if (action.payload.data) {
        return {
          ...state,
          devicesByDate: action.payload.data.map((row) => {
            return {
              ...row,
              createdAt: moment(row.created_at).utc().format('lll'),
            }
          }),
          length: Number(action.payload.headers.length),
        }
      }

      return { ...state }

    case FETCH_ALL_DEVICES_BY_PRODUCT:
      if (action.error) {
        return { ...state }
      }

      if (action.payload.data) {
        return { ...state, devicesByProduct: action.payload.data }
      }

      return { ...state }

    case FETCH_DEVICES_FIRMWARE_STATUS_IN_RANGE:
      if (action.error) {
        return { ...state }
      }

      if (action.payload.data) {
        return { ...state, devicesFirmwareStatusByDate: action.payload.data }
      }

      return { ...state }

    case FETCH_ALL_DEVICES_LOGS:
      if (action.error) {
        return { ...state }
      }

      if (action.payload.data) {
        return { ...state, devicesLogs: action.payload.data }
      }

      return { ...state }

    default:
      return state
  }
}
