// Import the const we define in our actions
import { FETCH_ALL_MACADDRESS } from '../actions'
import moment from 'moment'

// Put the default state as an object
export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_MACADDRESS:
      return action.payload.data
        ? {
            ...state,
            macAddress: action.payload.data.map((row) => {
              const time = moment.utc(row.createdAt).toDate()
              const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
              return {
                ...row,
                createdAt: local,
              }
            }),
            length: Number(action.payload.headers.length),
          }
        : { ...state }
    default:
      return state
  }
}
