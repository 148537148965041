import { FETCH_PROGRAMMER } from '../actions'

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_PROGRAMMER:
      return state

    default:
      return state
  }
}
