import {
  FETCH_SOFTWARE_UPDATE_RANGE,
  FETCH_ALL_SOFTWARE,
  FETCH_SOFTWARE,
} from '../actions'
import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_SOFTWARE:
      if (
        action.payload &&
        (action.payload.request.status === 200 ||
          action.payload.request.status === 206)
      )
        return {
          ...state,
          data: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
              link: 'Download',
              published: row.published.toString(),
            }
          }),
          length: Number(action.payload.headers.length),
        }

      return { ...state }

    case FETCH_SOFTWARE_UPDATE_RANGE:
      if (action.payload && action.payload.request.status === 200)
        return {
          ...state,
          updateRange: action.payload.data.map((row) => {
            return {
              ...row,
              createdAt: moment(row.createdAt).utc(1).format('lll'),
            }
          }),
          length: Number(action.payload.headers.length),
        }

      return { ...state }

    case FETCH_SOFTWARE:
      return { ...state, data: action.payload.data }

    default:
      return state
  }
}
