import { FETCH_STATISTICS_DASHBOARD } from '../actions'

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_STATISTICS_DASHBOARD:
      if (action.payload.data) {
        return { ...state, statistics: action.payload.data }
      } else return state

    default:
      return state
  }
}
