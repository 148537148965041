import { FETCH_ALL_CONFIGURATIONS } from '../actions'
import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_CONFIGURATIONS:
      if (
        action.payload &&
        (action.payload.request.status === 200 ||
          action.payload.request.status === 206)
      )
        return {
          ...state,
          data: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
              link: 'Download',
              published: row.published.toString(),
            }
          }),
          length: Number(action.payload.headers.length),
        }

      return { ...state }
    default:
      return state
  }
}
