import { GET_VID } from '../actions'
import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_VID:
      if (action.payload.data && action.payload.status === 206) {
        return {
          ...state,
          vid: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
            }
          }),
          length: Number(action.payload.data.length),
        }
      } else return state
    default:
      return state
  }
}
