import {
  ADD_USER_TO_GROUP,
  GET_USERS_BY_GROUP,
  GET_GROUPS,
  REMOVE_USER_FROM_GROUP,
} from '../actions'
import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_GROUPS:
      if (action.payload && action.payload.status === 200)
        return {
          ...state,
          data: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
            }
          }),
        }
      else return state

    case GET_USERS_BY_GROUP:
      if (action.payload && action.payload.status === 200)
        return { ...state, groupUsers: action.payload.data }
      else return state

    case ADD_USER_TO_GROUP:
      if (action.payload && action.payload.status === 200)
        return { ...state, userAdded: action.payload.data }
      else return state

    case REMOVE_USER_FROM_GROUP:
      if (action.payload && action.payload.status === 200)
        return { ...state, userRemoved: action.payload.data }
      else return state

    default:
      return state
  }
}
