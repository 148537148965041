import { GET_ALL_LOGS } from '../actions'
import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_LOGS:
      if (action.payload && action.payload.status === 204) {
        state.data = []
      }

      if (
        action.payload &&
        (action.payload.status === 200 || action.payload.status === 206)
      ) {
        return {
          ...state,
          data: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              data: JSON.stringify(row.data),
              createdAt: local,
            }
          }),
        }
      }

      return { ...state }
    default:
      return state
  }
}
