import {
  GET_ALL_USER_ACCESS,
  GET_USERS,
  GET_USERS_PROGRAMMED_DEVICES,
} from '../actions'

import moment from 'moment'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_USERS:
      if (action.payload && action.payload.status === 200)
        return {
          ...state,
          data: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
            }
          }),
        }
      else return state

    case GET_ALL_USER_ACCESS:
      if (action.payload && action.payload.status === 200)
        return { ...state, access: action.payload.data }
      else return state

    case GET_USERS_PROGRAMMED_DEVICES:
      if (action.error) {
        return { ...state }
      }

      if (action.payload.data) {
        return { ...state, devicesProgrammedByUser: action.payload.data }
      }

      return { ...state }

    default:
      return state
  }
}
