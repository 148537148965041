// Import the const we define in our actions
import { FETCH_ALL_HARDWARE, FETCH_HARDWARE_PERMISSIONS } from '../actions'
import moment from 'moment'

// Put the default state as an object
export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_HARDWARE:
      if (action.payload.data && 'length' in action.payload.headers) {
        return {
          ...state,
          hardware: action.payload.data.map((row) => {
            const time = moment.utc(row.createdAt).toDate()
            const local = moment(time).local().format('YYYY-MM-DD HH:mm:ss')
            return {
              ...row,
              createdAt: local,
            }
          }),
          length: Number(action.payload.headers.length),
        }
      } else {
        return { ...state }
      }
    case FETCH_HARDWARE_PERMISSIONS:
      if (action.payload && action.payload.data) {
        return { ...state, hardwarePermissions: action.payload.data }
      } else {
        return { ...state, hardwarePermissions: [] }
      }
    default:
      return state
  }
}
