import { FETCH_TOKEN_STATUS, LOGIN_USER } from '../actions'

export default function (state = {}, action) {
  switch (action.type) {
    case LOGIN_USER:
      if (action.payload && action.payload.status === 200)
        return { ...state, token: action.payload.headers.authorization }
      else return state

    case FETCH_TOKEN_STATUS:
      if (action.payload && action.payload.status !== 200)
        return { ...state, forceLogOut: 'true' }
      else return state

    default:
      return state
  }
}
